import React from "react";
import Logo from "../assets/img/logo.png";
import { Link, useNavigate } from "react-router-dom";

export default function Footer() {
  const Navigate = useNavigate();
  return (
    <div className="bg-slate-200 p-4">
      <div className="grid lg:grid-cols-5 grid-cols-2 gap-4 ">
        <div className="lg:col-span-1 col-span-2 flex justify-center items-center">
          <img className="w-6/12 md:w-8/12 max-w-xs" src={Logo} alt="Logo" />
        </div>
        <div className="lg:col-span-1 col-span-1 mt-4 md:mt-0">
          <div className="font-bold text-xl mb-4">Product</div>
          <div className="text-md py-2"> Restaurant Scheduling</div>
          <div className="text-md py-2">Team Communication</div>
          <div className="text-md py-2">Time Clocking</div>
          <div className="text-md py-2">Task Management</div>
          <div className="text-md py-2">Operations Overview</div>
        </div>
        <div className="lg:col-span-1 col-span-1 mt-4 md:mt-0">
          <div className="font-bold text-xl mb-4">Solution</div>
          <div className="text-md py-2">Mobile Scheduling</div>
          <div className="text-md py-2">Manager Log Book</div>
          <Link to="/teamMangement" className="text-md py-2">
            Team Management
          </Link>
          <div className="text-md py-2">Workforce Management</div>
          <div className="text-md py-2">Document Storage</div>
          <div className="text-md py-2">Employee Onboarding</div>
        </div>
        <div className="lg:col-span-1 col-span-2 mt-4 lg:mt-0">
          <div className="font-bold text-xl mb-4 ">Company</div>
          <Link to="/contactUs" className="text-md py-2 cursor-pointer">
            Contact us
          </Link>
          <div className="text-md py-2">
            <Link to="/about" className="text-md cursor-pointer">
              About us
            </Link>
          </div>
          <div className="text-md py-2">Careers</div>
          <div className="text-md py-2">Reviews</div>
          <div className="text-md py-2">Become A Partner</div>
          <div className="text-md py-2">Affiliates</div>
          <div className="text-md py-2">Media Kit</div>
          <div className="text-md py-2">Legal</div>
          <div className="text-md py-2">Pricing</div>
          <div className="text-md py-2">Sitemap</div>
        </div>
        <div className="lg:col-span-1 col-span-2 mt-4 lg:mt-0">
          <div className="font-bold text-xl mb-4 ">Resources</div>
          <div className="text-md py-2">
            <Link to="/download" className="text-md cursor-pointer">
              Download
            </Link>
          </div>
          <div className="text-md py-2">Blog</div>
          <div className="text-md py-2">Resource Center</div>
          <div className="text-md py-2">Restaurant Guides</div>
          <div className="text-md py-2">Restaurant Data</div>
          <div className="text-md py-2">Restaurant Podcast</div>
          <div className="text-md py-2">Academy</div>
          <div className="text-md py-2">Integrations</div>
        </div>
      </div>
      <div className="border-t border-white mt-4 pt-4 text-center text-sm">
        © Theme by shiftnpay. All Rights Reserved.
      </div>
    </div>
  );
}
