import React, { useState } from "react";
import Navbar from "../common/navBar";
import back from "../assets/img/food.png";
import Footer from "../common/footer";

export default function ContactUs() {
    const [switchChecked, setSwitchChecked] = useState(false);

  const handleSwitchChange = () => {
    setSwitchChecked(!switchChecked);
  };
  return (
    <div>
      <Navbar />
      <img className="w-full h-[282px]" src={back} alt="background" />
      <div className="font-bold text-2xl mt-10 mb-8 text-center">Contact Us</div>
      <div className="flex justify-center  mb-14">
        <form className="w-full lg:w-[1000px]">
          <div className="flex  justify-between  mb-6">
            <div className=" w-6/12   mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="first-name">
                First Name
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="first-name"
                type="text"
                placeholder="First Name"
              />
            </div>
            <div className=" w-5/12 ">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="last-name">
                Last Name
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="last-name"
                type="text"
                placeholder="Last Name"
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="email">
                Email
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="email"
                type="email"
                placeholder="Email"
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="message">
                Message
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="message"
                rows="5"
                placeholder="Your message"
              ></textarea>
            </div>
          </div>
          <div className="flex items-center justify-between mb-6 px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold" htmlFor="switch">
              Accept Terms & Conditions
            </label>
            <div className="relative inline-block w-10 mr-2 align-middle select-none">
              <input
                type="checkbox"
                name="switch"
                id="switch"
                checked={switchChecked}
                onChange={handleSwitchChange}
                className="switch-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
              />
              <label
                htmlFor="switch"
                className="switch-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
              ></label>
            </div>
          </div>
          <div className="flex justify-center">
            <button className="bg-green-600 w-full hover:bg-green-600 text-white font-bold py-2 px-4 rounded" type="submit">
              Send
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
}
