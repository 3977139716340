import React, { useEffect, useState } from "react";
import Footer from "../common/footer";
import groups from "../assets/img/groups.png";
import { useNavigate } from "react-router-dom";
import Navbar from "../common/navBar";
import CountUp from "react-countup";
import Lower from "../common/lower";
import track from "../assets/img/tracker.png";
import tawer from "../assets/img/tawer.png";
import mobile from "../assets/img/mobileTracker.png";
import playstore from "../assets/img/playstore.png";

export default function About() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4">
      <div className="grid p-2 lg:grid-cols-2 grid-cols-1 mt-10 justify-center ">
          <div className="lg:ml-14 mt-10">
            <div className="text-[55px] w-11/12 leading-12 font-bold text-[#3B3C4E]">
            We believe great teams make great restaurants
            </div>
            <div className="text-md text-slate-600 mt-10 font-sans">
            It exists with a core purpose: To free up time for managers to focus on serving their staff and customers. 
            </div>
            <div
              onClick={() => {
                navigate("/Login");
              }}
              className=" bg-[#108A00] rounded-2xl w-3/12 mt-4 cursor-pointer mb-6"
            >
              <div className="text-white p-2 text-center">Start Free Trial</div>
            </div>
          </div>
          <div>
            <img
              className={`w-10/12 ${isVisible ? "" : "opacity-0"}`}
              src={groups}
              alt="Groups"
              style={{
                opacity: isVisible ? 1 : 0,
                transition: "opacity 2s ease-in-out",
              }}
            />
          </div>
        </div>

        <div className="text-center text-[#3B3C4E] font-bold text-[24px] mt-14">
          Thousands Are Choosing Shiftnpay Time Tracker
        </div>
        <div className="lg:flex p-2 justify-center items-center gap-14 mt-6 text-center">
          <div>
            <div className="text-[36px] text-[#3B3C4E]">
              <CountUp end={11000000} duration={2} />
            </div>
            <div className="text-[18px] text-[#484A61] font-sans">
              Hours Tracked
            </div>
          </div>
          <div>
            <div className="text-[36px] text-[#3B3C4E]">
              <CountUp end={840000} duration={2} />
            </div>
            <div className="text-[18px] text-[#484A61] font-sans">
              Tasks Completed
            </div>
          </div>
          <div>
            <div className="text-[36px] text-[#3B3C4E]">
              <CountUp end={51000} duration={2} />
            </div>
            <div className="text-[18px] text-[#484A61] font-sans">
              Productive Users
            </div>
          </div>
          <div>
            <div className="text-[36px] text-[#3B3C4E]">
              <CountUp end={35000} duration={2} />
            </div>
            <div className="text-[18px] text-[#484A61] font-sans">
              Projects Succeeded
            </div>
          </div>
        </div>

        <div className="lg:flex gap-10 mt-6 justify-center items-center">
          <div className="lg:w-6/12">
            <img src={track} className="w-full" alt="Track" />
            <div className="lg:ml-20 mt-4">Create your schedule in minutes</div>
            <p className="w-full lg:w-6/12 lg:ml-20 mt-2 text-slate-600">
              Time is money. Put more time back into your workday by quickly
              creating and managing your schedules with us.
            </p>
          </div>
          <div className="lg:w-6/12 mt-10 lg:mt-0">
            <img src={tawer} className="w-full" alt="Tawer" />
            <div className="lg:ml-14 mt-4">
              Save $1,000s in labor costs each year
            </div>
            <p className="w-full lg:w-6/12 lg:ml-14 mt-2 text-slate-600">
              Avoid over or under-staffing by automatically forecasting sales
              and labor projections to build more accurate schedules.
            </p>
          </div>
        </div>

        <div className="lg:flex gap-10 mt-6 mb-4 justify-center items-center">
          <div className="lg:w-6/12">
            <img src={mobile} className="w-full" alt="Mobile Tracker" />
          </div>
          <div className="lg:w-6/12 mt-10 lg:mt-0">
            <h2 className="text-[24px] font-bold text-[#3B3C4E]">
              Download Our New App
            </h2>
            <p className="mt-2 text-slate-600 w-full lg:w-8/12">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
            <img src={playstore} className="w-4/12 mt-4" alt="Play Store" />
          </div>
        </div>

        <div className="bg-green-600 rounded-md p-4 mt-10">
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <div className="text-white text-center lg:text-left">
              Start saving your quick service time and money
            </div>
            <button
              className="bg-white text-green-600 rounded-md p-2 mt-4 lg:mt-0"
              onClick={() => navigate("/signup")}
            >
              Get Started Today
            </button>
          </div>
        </div>
      <Lower />
      </div>
      <Footer />
    </>
  );
}
